<app-layout-page-header>
  <div class="row row-space-between row-bottom">
    <div>
      <h3 class="row">
        <app-go-back />
        {{ 'Logs for' | translate }}
        <a
          *ngIf="
            selectedSolution?.status !== 'Canceled' &&
            selectedSolution?.status !== 'Pending' &&
            (hasFullRights || userIsVeoliaAuthorized || userIsVendorAuthorized)
          "
          nz-dropdown
          [nzDropdownMenu]="menu"
          style="display: flex">
          {{ selectedSolution?.solutionName! }}
          <veolia-icon
            [size]="24"
            [filled]="false"
            [name]="'arrow_drop_down'"></veolia-icon>
        </a>
        <strong
          *ngIf="
            selectedSolution?.status === 'Canceled' ||
            selectedSolution?.status === 'Pending' ||
            (!hasFullRights && !userIsVeoliaAuthorized && !userIsVendorAuthorized)
          ">
          {{ selectedSolution?.solutionName! }}
        </strong>
        <span class="row">
          <veolia-link
            *ngIf="
              selectedSolution?.status !== 'Canceled' &&
              selectedSolution?.status !== 'Pending' &&
              hasFullRights
            "
            size="small"
            icon="edit"
            (click)="launchAction('edit', selectedSolution!)">
            Edit
          </veolia-link>
          <veolia-link
            *ngIf="
              selectedSolution?.status !== 'Canceled' &&
              selectedSolution?.status !== 'Pending' &&
              canShare
            "
            size="small"
            icon="share"
            (click)="launchAction('share', selectedSolution!)">
            Share
          </veolia-link>
          <veolia-link
            size="small"
            icon="event_available"
            (click)="launchAction('summary', selectedSolution!)">
            Summary
          </veolia-link>
          <veolia-link
            size="small"
            icon="group"
            (click)="launchAction('access', selectedSolution!)">
            Access rights
          </veolia-link>
        </span>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(selectedSolution!, 'designForm')">
                Design Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(selectedSolution!, 'legalForm')">
                Legal Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(selectedSolution!, 'article32Form')">
                Article 32
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(selectedSolution!, 'article28Form')">
                Annexes
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </h3>
      <br />
      <veolia-segmented
        *ngIf="selectedSolution?.type === 'SaaS'"
        [options]="[
          { label: 'Design Form', value: 0 },
          { label: 'Legal Form', value: 1 },
          { label: 'Annexes', value: 2 },
          { label: 'Article 32', value: 3 }
        ]"
        (handleIndexChange)="onFormChange($event)"
        [variant]="'primary'"
        [selectedIndex]="formIndexSaaS()"
        [size]="'default'" />
      <veolia-segmented
        *ngIf="selectedSolution?.type === 'IaaS'"
        [options]="[{ label: 'IaaS', value: 0 }]"
        (handleIndexChange)="onFormChange($event)"
        [variant]="'primary'"
        [selectedIndex]="formIndexIaaS()"
        [size]="'default'" />
    </div>
    <div>
      <div class="buttons row end">
        <veolia-button
          [size]="'default'"
          [variant]="'primary'"
          [htmlType]="'button'"
          [icon]="'info'"
          (click)="launchAction('info', selectedSolution!)">
          About this request
        </veolia-button>
      </div>
    </div>
  </div>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content">
    <ng-template #loginTab>
      <app-logs-tab-icon
        icon_name="login"
        title="Login on form"></app-logs-tab-icon>
    </ng-template>

    <ng-template #saveTab>
      <app-logs-tab-icon icon_name="save" title="Form save"></app-logs-tab-icon>
    </ng-template>

    <ng-template #sendTab>
      <app-logs-tab-icon
        icon_name="send"
        title="Save and send"></app-logs-tab-icon>
    </ng-template>

    <ng-template #attachFile>
      <app-logs-tab-icon
        icon_name="attach_file"
        title="Files"></app-logs-tab-icon>
    </ng-template>

    <ng-template #commentTab>
      <app-logs-tab-icon
        icon_name="comment"
        title="Comments"></app-logs-tab-icon>
    </ng-template>

    <ng-template #accountTree>
      <app-logs-tab-icon
        icon_name="account_tree"
        title="Chapter/sub-chapter completed"></app-logs-tab-icon>
    </ng-template>

    <ng-template #helpTab>
      <app-logs-tab-icon
        icon_name="help"
        title="Help requested"></app-logs-tab-icon>
    </ng-template>

    <ng-template #checkTab>
      <app-logs-tab-icon
        icon_name="check_box"
        title="Evaluations"></app-logs-tab-icon>
    </ng-template>

    <ng-template #saveCheckTab>
      <app-logs-tab-icon
        icon_name="playlist_add_check"
        title="Save final evaluations"></app-logs-tab-icon>
    </ng-template>

    <ng-template #acunetixTab>
      <app-logs-tab-icon
        icon_name="security"
        title="Acunetix save"></app-logs-tab-icon>
    </ng-template>

    <nz-tabset
      *ngIf="selectedSolution?.type === 'SaaS'"
      (nzSelectedIndexChange)="onTabChange($event)">
      <nz-tab [nzTitle]="loginTab">
        <app-form-logs
          *ngIf="currentTab === 0"
          actionName="Connection"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="saveTab">
        <app-form-logs
          *ngIf="currentTab === 1"
          actionName="SaveForm"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="sendTab">
        <app-form-logs
          *ngIf="currentTab === 2"
          actionName="SaveCompleteForm"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="attachFile">
        <app-form-logs
          *ngIf="currentTab === 3"
          actionName="File"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="commentTab">
        <app-form-logs
          *ngIf="currentTab === 4"
          actionName="Comment"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="accountTree">
        <app-form-logs
          *ngIf="currentTab === 5"
          actionName="Complete"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="helpTab">
        <app-form-logs
          *ngIf="currentTab === 6"
          actionName="Help"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="checkTab">
        <app-form-logs
          *ngIf="currentTab === 7"
          actionName="Evaluation"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="saveCheckTab">
        <app-form-logs
          *ngIf="currentTab === 8"
          actionName="FinalEvaluation"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="acunetixTab">
        <app-form-logs
          *ngIf="currentTab === 9"
          actionName="Acunetix"
          [formName]="formNameSaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
    </nz-tabset>

    <nz-tabset *ngIf="selectedSolution?.type === 'IaaS'">
      <nz-tab [nzTitle]="loginTab">
        <app-form-logs
          actionName="Connection"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="saveTab">
        <app-form-logs
          actionName="SaveForm"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="sendTab">
        <app-form-logs
          actionName="SaveCompleteForm"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="attachFile">
        <app-form-logs
          actionName="File"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="commentTab">
        <app-form-logs
          actionName="Comment"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="accountTree">
        <app-form-logs
          actionName="Complete"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="helpTab">
        <app-form-logs
          actionName="Help"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="checkTab">
        <app-form-logs
          actionName="Evaluation"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="saveCheckTab">
        <app-form-logs
          actionName="FinalEvaluation"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
      <nz-tab [nzTitle]="acunetixTab">
        <app-form-logs
          actionName="Acunetix"
          [formName]="formNameIaaS()"
          [solutionId]="selectedSolution?.uid!"></app-form-logs>
      </nz-tab>
    </nz-tabset>
  </div>
</div>

<app-access-rights #accessRightsModal [businessUnit]="businessUnit" />

<app-solution-share #solutionShareModal />

<app-solution-info-summary #solutionInfoSummaryModal />
