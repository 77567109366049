<veolia-modal #accessRightsModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Access rights for ' | translate }}
      <strong>{{ solName! }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <ng-container *ngIf="vm$ | async as vm">
      <div class="center">
        <veolia-segmented
          [options]="hasFullRights ? options : simpleOptions"
          (handleIndexChange)="handleIndexChange($event)"
          [variant]="'primary'"
          [selectedIndex]="mode" />
      </div>

      <!--User List-->
      <div *ngIf="mode === 0">
        <div class="spacing-v16" style="text-align: center">
          <h3 *ngIf="vm.solution" class="form-header">
            List of users access for solution
            <strong>
              {{ vm.solution.solutionName | uppercase }}
            </strong>
          </h3>
        </div>
        <div class="spacing-v16" *ngIf="vm.solution?.solutionOwner">
          <span
            ><span nz-typography>Current solution owner : </span
            ><a>{{ vm.solution?.solutionOwner }}</a></span
          >
        </div>
        <div class="spacing-v16" *ngIf="vm.solution?.emailVeoliaAuthorized">
          <h3>Veolia users:</h3>
          <ng-container>
            <app-access-right-user
              *ngFor="let item of vm.solution?.emailVeoliaAuthorized!"
              [isDeletable]="hasFullRights"
              [email]="item"
              [type]="typeOfUser('veolia')"
              [solutionId]="vm.solutionId"
              [solutionOwner]="vm.solution?.solutionOwner"
              (removed)="refreshSolution()">
            </app-access-right-user>
          </ng-container>
        </div>
        <div class="spacing-v16" *ngIf="vm.solution?.emailVendorAuthorized">
          <h3>External users:</h3>
          <ng-container>
            <app-access-right-user
              *ngFor="let item of vm.solution?.emailVendorAuthorized!"
              [isDeletable]="hasFullRights"
              [email]="item"
              [type]="typeOfUser('vendor')"
              [solutionId]="vm.solutionId"
              (removed)="refreshSolution()"></app-access-right-user>
          </ng-container>
        </div>
      </div>

      <!--Evaluator List-->
      <div *ngIf="mode === 1">
        <div class="spacing-v16" style="text-align: center">
          <h3 *ngIf="vm.solution" class="form-header">
            List of evaluators for solution
            <strong>
              {{ vm.solution.solutionName | uppercase }}
            </strong>
          </h3>
        </div>
        <div class="spacing-v16" *ngIf="vm.evaluatorList">
          <h3>
            Evaluators for this BU ({{ vm.solution?.businessUnitOfSolution! }}):
          </h3>
          <app-access-right-user
            *ngFor="let item of vm.evaluatorList"
            [email]="item"></app-access-right-user>
        </div>
        <div class="spacing-v16">
          <h3>Evaluators added for this solution:</h3>
          <app-access-right-user
            *ngFor="let item of vm.solution?.emailEvaluatorAuthorized!"
            [isDeletable]="hasFullRights"
            [email]="item"
            [type]="typeOfUser('evaluatorSol')"
            [solutionId]="vm.solutionId"
            (removed)="refreshSolution()"></app-access-right-user>
        </div>
      </div>

      <!--Access Management-->
      <div *ngIf="mode === 2">
        <div class="spacing-v16">
          <form
            nz-form
            [nzLayout]="'vertical'"
            [formGroup]="changeSoForm!"
            (ngSubmit)="submitFormChangeSo()">
            <h4 *ngIf="vm.solution" class="form-header">
              Change the Solution Owner for {{ vm.solution.solutionName }} (A or
              B option)
            </h4>
            <span
              ><span nz-typography>Current solution owner : </span
              ><a>{{ vm.solution?.solutionOwner }}</a></span
            >
            <div>
              <nz-alert
                *ngIf="emptyMessage"
                nzType="error"
                [nzMessage]="emptyMessage"
                nzShowIcon></nz-alert>
            </div>
            <div *ngIf="vm$ | async as vm">
              <nz-form-item class="full-width">
                <nz-form-label>
                  (A) Chose the veolia user you want to indicate as the new
                  solution owner
                </nz-form-label>
                <nz-form-control>
                  <nz-select
                    nzPlaceHolder="Select a veolia"
                    (nzOnSearch)="onSearch()"
                    class="light-gray"
                    formControlName="emailVeoliaAccountExisting"
                    nzAllowClear
                    nzShowSearch>
                    <ng-container *ngFor="let u of vm.users">
                      <nz-option
                        [nzValue]="u.email"
                        [nzLabel]="u.name"></nz-option>
                    </ng-container>
                    <nz-option
                      *ngIf="vm.isLoadingUsers"
                      nzDisabled
                      nzCustomContent>
                      <span
                        nz-icon
                        nzType="loading"
                        class="loading-icon"></span>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="row">
              <nz-form-item class="full-width">
                <nz-form-label>
                  (B) If he is not present in the list, you can provide his
                  email in field bellow
                </nz-form-label>
                <nz-form-control nzErrorTip="Please type an e-mail">
                  <nz-input-group nzAddOnAfter="@veolia.com">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="emailVeoliaAccountNotExisting"
                      mask="A*"
                      [patterns]="customPatterns"
                      [validation]="true"
                      [dropSpecialCharacters]="false" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="row">
              <span nz-typography nzDisabled>
                Only your organization's domain name (cannot be changed)
              </span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <div class="row row-right">
                  <div>
                    <veolia-button
                      *ngIf="!vm.isLoadingUsers"
                      [size]="'default'"
                      [variant]="'secondary'"
                      [htmlType]="'reset'"
                      (click)="close()">
                      Reset
                    </veolia-button>
                  </div>
                  <div>
                    <veolia-button
                      *ngIf="!vm.isLoadingUsers"
                      [size]="'default'"
                      [variant]="'primary'"
                      [htmlType]="'submit'">
                      Apply
                    </veolia-button>
                  </div>
                  <simple-spinner *ngIf="vm.isLoading"></simple-spinner>
                </div>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
        <nz-divider></nz-divider>
        <div class="spacing-v16">
          <h4 *ngIf="vm.solution" class="form-header">
            Manage external users access rights for forms
            <strong>
              {{ vm.solution.solutionName | uppercase }}
            </strong>
          </h4>
        </div>
        <div class="spacing-v16" *ngIf="vm.solution?.emailVendorAuthorized">
          <nz-list nzBordered nzSize="small">
            <nz-list-header>
              <div class="row row-space-between">
                <span>External users</span>
                <div class="forms">
                  <div class="actions-label">Design Form</div>
                  <div class="actions-label">Legal Form</div>
                  <div class="actions-label">Annexes</div>
                  <div class="actions-label">Article 32</div>
                </div>
              </div>
            </nz-list-header>
            <nz-list-item
              *ngFor="let item of vm.solution?.emailVendorAuthorized!">
              <div class="row row-space-between">
                <span>{{ item }}</span>
                <div class="forms">
                  <app-access-right-button
                    [solution]="vm.solution"
                    [email]="item"
                    form="design"
                    (changed)="refreshSolution()"></app-access-right-button>
                  <app-access-right-button
                    [solution]="vm.solution"
                    [email]="item"
                    form="legal"
                    (changed)="refreshSolution()"></app-access-right-button>
                  <app-access-right-button
                    [solution]="vm.solution"
                    [email]="item"
                    form="art28"
                    (changed)="refreshSolution()"></app-access-right-button>
                  <app-access-right-button
                    [solution]="vm.solution"
                    [email]="item"
                    form="art32"
                    (changed)="refreshSolution()"></app-access-right-button>
                </div>
              </div>
            </nz-list-item>
          </nz-list>
        </div>
      </div>
    </ng-container>
  </div>
</veolia-modal>
