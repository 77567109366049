<app-layout-page-header>
  <ng-template #title>
    <h3 class="row">
      <app-go-back />
      {{ 'Assessments summary for' | translate }}
      <a
        *ngIf="
          selectedSolution?.status !== 'Canceled' &&
          selectedSolution?.status !== 'Pending' &&
          (hasFullRights || userIsVeoliaAuthorized || userIsVendorAuthorized)
        "
        nz-dropdown
        [nzDropdownMenu]="menu"
        style="display: flex">
        {{ selectedSolution.solutionName }}
        <veolia-icon
          [size]="24"
          [filled]="false"
          [name]="'arrow_drop_down'"></veolia-icon>
      </a>
      <strong
        *ngIf="
          selectedSolution?.status === 'Canceled' ||
          selectedSolution?.status === 'Pending' ||
          (!hasFullRights && !userIsVeoliaAuthorized && !userIsVendorAuthorized)
        ">
        {{ selectedSolution?.solutionName! }}
      </strong>
      <span class="row">
        <veolia-link
          *ngIf="
            selectedSolution.status !== 'Canceled' &&
            selectedSolution.status !== 'Pending' &&
            hasFullRights
          "
          size="small"
          icon="edit"
          (click)="launchAction('edit', selectedSolution)">
          Edit
        </veolia-link>
        <veolia-link
          *ngIf="
            selectedSolution.status !== 'Canceled' &&
            selectedSolution.status !== 'Pending' &&
            canShare
          "
          size="small"
          icon="share"
          (click)="launchAction('share', selectedSolution)">
          Share
        </veolia-link>
        <veolia-link
          size="small"
          icon="list_alt"
          (click)="launchAction('logs', selectedSolution)">
          Logs
        </veolia-link>
        <veolia-link
          size="small"
          icon="group"
          (click)="launchAction('access', selectedSolution!)">
          Access rights
        </veolia-link>
      </span>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item>
            <veolia-link
              size="small"
              (click)="launchForm(selectedSolution, 'designForm')">
              Design Form
            </veolia-link>
          </li>
          <li nz-menu-item>
            <veolia-link
              size="small"
              (click)="launchForm(selectedSolution, 'legalForm')">
              Legal Form
            </veolia-link>
          </li>
          <li nz-menu-item>
            <veolia-link
              size="small"
              (click)="launchForm(selectedSolution, 'article32Form')">
              Article 32
            </veolia-link>
          </li>
          <li nz-menu-item>
            <veolia-link
              size="small"
              (click)="launchForm(selectedSolution, 'article28Form')">
              Annexes
            </veolia-link>
          </li>
        </ul>
      </nz-dropdown-menu>
    </h3>
  </ng-template>
  <nz-descriptions
    nzBordered
    [nzTitle]="title"
    [nzColumn]="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
    [nzExtra]="buttons">
    <nz-descriptions-item
      nzTitle="{{ 'Editor Brand' | translate }}"
      [nzSpan]="1">
      <strong>{{ selectedSolution.editorBrand }}</strong>
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="{{ 'Business Unit' | translate }}"
      [nzSpan]="1">
      <strong>
        {{ selectedSolution.businessUnitOfSolution }}
      </strong>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="{{ 'Created on' | translate }}" [nzSpan]="1">
      <strong>{{
        selectedSolution.requestDate.toDate() | date: 'longDate'
      }}</strong>
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="{{ 'Last update at' | translate }}"
      [nzSpan]="1">
      <strong *ngIf="!selectedSolution.lastUpdate.toDate">
        {{ 'No forms completed yet' }}
      </strong>
      <strong *ngIf="selectedSolution.lastUpdate.toDate">
        {{ selectedSolution.lastUpdate.toDate() | date: 'longDate' }}</strong
      >
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="{{ 'Solution Owner' | translate }}"
      [nzSpan]="2">
      <strong>
        <veolia-link
          [variant]="'primary'"
          [size]="'large'"
          (click)="mailTo(selectedSolution.solutionOwner)">
          {{ selectedSolution.solutionOwner }}
        </veolia-link>
      </strong>
    </nz-descriptions-item>
  </nz-descriptions>
  <ng-template #buttons>
    <div class="buttons row end">
      <veolia-button
        [size]="'default'"
        [variant]="'primary'"
        [htmlType]="'button'"
        [icon]="'info'"
        (click)="launchAction('info', selectedSolution!)">
        About this request
      </veolia-button>
    </div>
  </ng-template>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content">
    <nz-list nzGrid>
      <div nz-row [nzGutter]="16">
        <div
          nz-col
          [nzXXl]="8"
          [nzXl]="8"
          [nzLg]="12"
          [nzMd]="12"
          [nzSm]="12"
          [nzXs]="24"
          *ngIf="selectedSolution?.type === 'SaaS'">
          <nz-list-item>
            <app-form-summary-panel
              title="Design"
              formName="Design Form"
              [formStatus]="designForm?.designStatus || 'DLA Assessment'"
              [reservations]="designFormReservations"
              (onOpenOverview)="
                openFormInfoModal(formNameEnum.Design, designForm!)
              "
              (onOpenEvaluation)="
                openEvaluationModal(formNameEnum.Design, designForm!)
              "
              (onOpenReservation)="openReservationOverviewModal($event)"
              [solutionId]="selectedSolution.uid"></app-form-summary-panel>
          </nz-list-item>
        </div>
        <div
          nz-col
          [nzXXl]="8"
          [nzXl]="8"
          [nzLg]="12"
          [nzMd]="12"
          [nzSm]="12"
          [nzXs]="24"
          *ngIf="selectedSolution?.type === 'SaaS'">
          <nz-list-item>
            <app-form-summary-panel
              title="Legal"
              formName="Legal Form"
              [formStatus]="legalForm?.legalStatus || 'DLA Assessment'"
              [reservations]="legalFormReservations"
              (onOpenOverview)="
                openFormInfoModal(formNameEnum.Legal, legalForm!)
              "
              (onOpenEvaluation)="
                openEvaluationModal(formNameEnum.Legal, legalForm!)
              "
              (onOpenReservation)="openReservationOverviewModal($event)"
              [solutionId]="selectedSolution.uid">
            </app-form-summary-panel>
          </nz-list-item>
        </div>
        <div
          nz-col
          [nzXXl]="8"
          [nzXl]="8"
          [nzLg]="12"
          [nzMd]="12"
          [nzSm]="12"
          [nzXs]="24"
          *ngIf="selectedSolution?.type === 'SaaS'">
          <nz-list-item>
            <app-form-summary-panel
              title="Article 32"
              formName="Article 32 Form"
              [formStatus]="art32Form?.article32Status || 'DLA Assessment'"
              [reservations]="art32FormReservations"
              (onOpenOverview)="
                openFormInfoModal(formNameEnum.Article32, art32Form!)
              "
              (onOpenEvaluation)="
                openEvaluationModal(formNameEnum.Article32, art32Form!)
              "
              (onOpenReservation)="openReservationOverviewModal($event)"
              [solutionId]="selectedSolution.uid"></app-form-summary-panel>
          </nz-list-item>
        </div>
        <div
          nz-col
          [nzXXl]="8"
          [nzXl]="8"
          [nzLg]="12"
          [nzMd]="12"
          [nzSm]="12"
          [nzXs]="24"
          *ngIf="selectedSolution?.type === 'SaaS'">
          <nz-list-item>
            <app-form-summary-panel
              title="Annexes"
              formName="Annexes"
              [formStatus]="art28Form?.article28Status || 'DLA Assessment'"
              [reservations]="art28FormReservations"
              (onOpenOverview)="
                openFormInfoModal(formNameEnum.Article28, art28Form!)
              "
              (onOpenEvaluation)="
                openEvaluationModal(formNameEnum.Article28, art28Form!)
              "
              (onOpenReservation)="openReservationOverviewModal($event)"
              [solutionId]="selectedSolution.uid"></app-form-summary-panel>
          </nz-list-item>
        </div>
        <div
          nz-col
          [nzXXl]="8"
          [nzXl]="8"
          [nzLg]="12"
          [nzMd]="12"
          [nzSm]="12"
          [nzXs]="24"
          *ngIf="selectedSolution?.type === 'IaaS'">
          <nz-list-item>
            <app-form-summary-panel
              title="IaaS"
              formName="IaaS Form"
              [formStatus]="iaaSForm?.iaasStatus"
              [reservations]="iaaSFormReservations"
              (onOpenOverview)="
                openFormInfoModal(formNameEnum.IAAS, art28Form!)
              "
              (onOpenEvaluation)="
                openEvaluationModal(formNameEnum.IAAS, iaaSForm!)
              "
              (onOpenReservation)="openReservationOverviewModal($event)"
              [solutionId]="selectedSolution.uid"></app-form-summary-panel>
          </nz-list-item>
        </div>
      </div>
    </nz-list>
  </div>
</div>

<!--for 'about this form' in summary/id page-->
<veolia-modal #formModal>
  <div veolia-modal-title>
    {{ formName }} for
    <strong>
      {{ selectedSolution.solutionName }}
    </strong>
  </div>
  <div veolia-modal-content>
    <nz-card nzTitle="Form informations" class="spacing-v16">
      <div>
        <p>
          {{ 'Version json-FR' | translate }}:
          <strong>{{ selectedSolution.jsonFR }}</strong>
        </p>
        <p>
          {{ 'Version json-US' | translate }}:
          <strong>{{ selectedSolution.jsonUS }}</strong>
        </p>
        <p>
          {{ 'Form created on' | translate }}:
          <strong *ngIf="!this.currentForm?.createdAt">
            {{ 'The vendor/SO has not yet accessed the form' }}
          </strong>
          <strong *ngIf="this.currentForm?.createdAt">
            {{
              this.currentForm?.createdAt?.toDate() | date: 'MMMM d, y, h:mm a'
            }}
          </strong>
        </p>
        <p>
          {{ 'Status of the current form' | translate }}:
          <strong *ngIf="!formStatus">{{
            'The vendor did not begin to complete the form'
          }}</strong>
          <strong *ngIf="formStatus">{{ formStatus }}</strong>
        </p>
        <p>
          {{ 'First update of the form' | translate }}:
          <strong *ngIf="!this.currentForm?.firstUpdate">
            {{ 'The vendor did not begin to complete the form' }}
          </strong>
          <strong *ngIf="this.currentForm?.firstUpdate">
            {{
              this.currentForm?.firstUpdate?.toDate()
                | date: 'MMMM d, y, h:mm a'
            }}
          </strong>
        </p>
        <p>
          {{ 'First update of the form by' | translate }}:
          <strong>
            {{
              this.currentForm?.firstUpdateBy ||
                'The vendor did not begin to complete the form'
            }}
          </strong>
        </p>
        <p>
          {{ 'Last update of the form' | translate }}:
          <strong *ngIf="!this.currentForm?.lastUpdate">
            {{ 'The vendor did not begin to complete the form' }}
          </strong>
          <strong *ngIf="this.currentForm?.lastUpdate">
            {{
              this.currentForm?.lastUpdate?.toDate() | date: 'MMMM d, y, h:mm a'
            }}
          </strong>
        </p>
        <p>
          {{ 'Last update of the form by' | translate }}:
          <strong>
            {{
              this.currentForm?.lastUpdateBy ||
                'The vendor did not begin to complete the form'
            }}
          </strong>
        </p>
      </div>
    </nz-card>
  </div>
</veolia-modal>

<app-summary-reservation-overview
  #summaryReservationOverview
  [formName]="formName"
  [reservations]="currentReservations"
  [isVisible]="showReservationOverviewModal"
  (closeModal)="closeReservationOverviewModal()"
  (editedReservation)="onEditedReservation($event)"
  [solution]="selectedSolution"></app-summary-reservation-overview>

<app-access-rights #accessRightsModal [businessUnit]="businessUnit" />

<app-solution-share #solutionShareModal />

<app-solution-info-summary #solutionInfoSummaryModal />

<app-evaluation-summary
  [evaluation]="currentEvaluation"
  [fileList]="currentFileList"
  [files]="files"
  [currentForm]="currentForm"
  [formName]="formName"
  [solution]="selectedSolution"
  [businessUnit]="businessUnit"
  #evaluationSummaryModal />
