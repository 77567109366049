import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import {
  VeoliaModalComponent,
  VeoliaModalModule,
} from '@veolia.com/vds-angular-components/modal';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { getTagColor } from 'src/app/core/utils/colors';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { Evaluation } from 'src/app/core/models/evalutation';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EvaluationSummaryModule } from '../../../evaluation-summary/evaluation-summary.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { FileItem } from 'src/app/core/models/file-item';
import { CommonModule } from '@angular/common';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { EvaluationApi } from 'src/app/core/apis/evaluation.api';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LogService } from 'src/app/core/services/log.service';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/core/services/auth.service';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  standalone: true,
  selector: 'app-form-assessment',
  templateUrl: './form-assessment.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    EvaluationSummaryModule,
    FormsModule,
    FormlyModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzListModule,
    NzRadioModule,
    ReactiveFormsModule,
    TranslateModule,
    VeoliaButtonComponent,
    VeoliaIconComponent,
    VeoliaModalModule,
    VeoliaTagComponent,
  ],
  providers: [TranslateService],
})
export class FormAssessmentComponent implements OnInit, OnChanges {
  @Input() evaluation?: Evaluation;
  @Input() form?:
    | Article28Form
    | Article32Form
    | DesignForm
    | LegalForm
    | IaasForm;
  @Input() formName?:
    | 'Design Form'
    | 'Legal Form'
    | 'Article 28 Form'
    | 'Article 32 Form'
    | 'IaaS Form';
  @Input() solution?: Solution;
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();

  evaluationStatus?: string = 'Form Assessment';
  evaluationColor?:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['evaluation']) {
      this.evaluation = {
        ...(changes['evaluation'].currentValue as unknown as Evaluation),
      };

      this.evaluationColor = getTagColor(
        this.evaluation?.status || 'Form assessment'
      );
      this.evaluationStatus = this.evaluation?.status;
    }
  }

  ngOnInit(): void {
    this.evaluationColor = getTagColor(
      this.evaluation?.status || 'Form assessment'
    );
  }
}
