<app-layout-page-header>
  <div class="row">
    <div class="row">
      <app-go-back />
      <app-layout-page-header-title
        title="{{ selectedSolution?.type }} Solution {{
          selectedSolution?.solutionName
        }}, editor ({{ selectedSolution?.editorBrand }}){{
          selectedSolution?.rfpName
            ? ', RFP (' + selectedSolution?.rfpName + ')'
            : ''
        }}"
        subtitle="Last update of the form: {{
          formDataUpdated?.lastUpdate?.toDate
            ? (formDataUpdated?.lastUpdate!.toDate()
              | date: 'MMMM d, y, h:mm a')
            : 'No forms completed yet'
        }}"
        subtitle_two="Last update by: {{
          formDataUpdated?.lastUpdateBy
            ? formDataUpdated?.lastUpdateBy
            : 'No forms completed yet'
        }}"
        subtitle_three="Current form status: {{
          formDataUpdated?.status
            ? formDataUpdated?.status
            : 'No forms completed yet'
        }}"></app-layout-page-header-title>
      <div class="row">
        <veolia-link
          *ngIf="
            selectedSolution?.status !== 'Canceled' &&
            selectedSolution?.status !== 'Pending' &&
            hasFullRights
          "
          size="small"
          icon="edit"
          (click)="launchAction('edit', selectedSolution!)">
          Edit
        </veolia-link>
        <veolia-link
          *ngIf="
            selectedSolution?.status !== 'Canceled' &&
            selectedSolution?.status !== 'Pending' &&
            canShare
          "
          size="small"
          icon="share"
          (click)="launchAction('share', selectedSolution!)">
          Share
        </veolia-link>
        <veolia-link
          size="small"
          icon="event_available"
          (click)="launchAction('summary', selectedSolution!)">
          Summary
        </veolia-link>
        <veolia-link
          size="small"
          icon="list_alt"
          (click)="launchAction('logs', selectedSolution!)">
          Logs
        </veolia-link>
        <veolia-link
          size="small"
          icon="group"
          (click)="launchAction('access', selectedSolution!)">
          Access rights
        </veolia-link>
        <veolia-link
          size="small"
          icon="help"
          (click)="launchAction('help', selectedSolution!)">
          Help
        </veolia-link>
      </div>
      <app-document-presence
        *ngIf="currentFormName"
        [documentId]="selectedSolution?.uid"
        [form]="currentFormName!" />
    </div>
  </div>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content spacing-b16">
    <div class="row row-end"></div>
    <ng-template #actionButtons>
      <div class="buttons row end">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
    </ng-template>
    <veolia-tabs
      *ngIf="formName !== 'iaasForm'"
      #formTabs
      [extraContent]="actionButtons"
      (tabChanged)="formChanged($event)">
      <veolia-tab label="Design Form">
        <app-design-form
          *ngIf="formName === 'designForm'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)" />
      </veolia-tab>
      <veolia-tab label="Legal Form">
        <app-legal-form
          *ngIf="formName === 'legalForm'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)" />
      </veolia-tab>
      <veolia-tab label="Article 32 Form">
        <app-article-32-form
          *ngIf="formName === 'article32Form'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)" />
      </veolia-tab>
      <veolia-tab label="Annexes">
        <app-article-28-form
          *ngIf="formName === 'article28Form'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)" />
      </veolia-tab>
    </veolia-tabs>
    <app-iaas-form
      *ngIf="formName === 'iaasForm'"
      [businessUnit]="selectedBusinessUnit"
      [solution]="selectedSolution"
      (updateFormDate)="formDateUpdate($event)" />
  </div>
</div>

<app-access-rights #accessRightsModal [businessUnit]="selectedBusinessUnit" />

<app-solution-share #solutionShareModal />

<app-contact-form #contactFormModal />
