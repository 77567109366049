import { Component, Input, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Solution } from 'src/app/core/models/solution';
import { LogsPageStore } from './logs-page.store';
import { AuthService } from 'src/app/core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { BusinessUnit } from 'src/app/core/models/business-unit';

@Component({
  selector: 'app-logs-page',
  templateUrl: './logs-page.component.html',
  styleUrls: ['./logs-page.component.scss'],
  providers: [LogsPageStore],
})
export class LogsPageComponent extends DataTableComponent implements OnInit {
  selectedSolution?: Solution;
  businessUnit?: BusinessUnit;
  article28Form?: Article28Form;
  article32Form?: Article32Form;
  designForm?: DesignForm;
  legalForm?: LegalForm;
  iaasForm?: IaasForm;
  formNamesSaaS = [
    'Design Form',
    'Legal Form',
    'Article 28 Form',
    'Article 32 Form',
  ];
  formNamesIaaS = ['IaaS Form'];
  formIndexSaaS = signal(0);
  formIndexIaaS = signal(0);
  formNameSaaS = signal(this.formNamesSaaS[0]);
  formNameIaaS = signal(this.formNamesIaaS[0]);
  currentTab = 0;

  #service = inject(VeoliaModalService);

  constructor(
    private route: ActivatedRoute,
    private logsPageStore: LogsPageStore,
    private auth: Auth,
    private article28FormService: Article28FormService,
    private article32FormService: Article32FormService,
    private designFormService: DesignFormService,
    private legalFormService: LegalFormService,
    private iaasFormService: IaaSFormService,
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public override solutionService: SolutionService,
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.selectedSolution = data['solution'] as Solution;
      this.businessUnit = data['businessUnit'] as BusinessUnit;
      this.logsPageStore.setSolutionId(this.selectedSolution.uid!);
    });
  }

  ngAfterViewInit(): void {
    if (this.selectedSolution?.uid) {
      this.article28FormService.get(this.selectedSolution?.uid).then(data => {
        this.article28Form = { uid: data.id, ...data.data() } as Article28Form;
      });

      this.article32FormService.get(this.selectedSolution?.uid).then(data => {
        if (data.exists()) {
          this.article32Form = {
            uid: data.id,
            ...data.data(),
          } as Article32Form;
        }
      });

      this.designFormService.get(this.selectedSolution?.uid).then(data => {
        if (data.exists()) {
          this.designForm = { uid: data.id, ...data.data() } as DesignForm;
        }
      });

      this.legalFormService.get(this.selectedSolution?.uid).then(data => {
        if (data.exists()) {
          this.legalForm = { uid: data.id, ...data.data() } as LegalForm;
        }
      });

      this.iaasFormService.get(this.selectedSolution?.uid).then(data => {
        if (data.exists()) {
          this.iaasForm = { uid: data.id, ...data.data() } as IaasForm;
        }
      });
    }
  }

  get userIsSoOfSolution() {
    if (this.selectedSolution?.solutionOwner === this.auth.currentUser?.email) return true;
    else return false;
  }

  get userIsVeoliaAuthorized() {
    if (!this.selectedSolution?.emailVeoliaAuthorized?.includes(this.auth.currentUser?.email!)) {
      return false;
    } else {
      return true;
    }
  }

  get userIsVendorAuthorized() {
    if (!this.selectedSolution?.emailVendorAuthorized?.includes(this.auth.currentUser?.email!)) {
      return false;
    } else {
      return true;
    }
  }

  get isBusinessUnitEvaluator() {
    if (!this.businessUnit?.evaluators?.includes(this.auth.currentUser?.email!)) {
      return false;
    } else {
      return true;
    }
  }

  get isSolutionEvaluator() {
    return this.selectedSolution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get hasFullRights() {
    return (
      this.authService.roles?.admin ||
      (this.authService.roles?.evaluator && this.isBusinessUnitEvaluator) ||
      this.isSolutionEvaluator ||
      this.userIsSoOfSolution
    );
  }

  get canShare() {
    return (
      this.hasFullRights ||
      this.userIsVeoliaAuthorized ||
      this.userIsVendorAuthorized
    );
  }

  onFormChange(e: number) {
    this.formIndexSaaS.set(e);
    this.formIndexIaaS.set(e);

    if (this.selectedSolution?.type === 'SaaS') {
      this.formNameSaaS.set(this.formNamesSaaS[e]);
    }
    if (this.selectedSolution?.type === 'IaaS') {
      this.formNameIaaS.set(this.formNamesIaaS[e]);
    }
  }

  onTabChange(tab: number) {
    this.currentTab = tab;
  }
}
